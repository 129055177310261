import { UpdateDriverScoreRequestInterface } from '@/data/payload/contracts/DriverScoreboardRequest'
import { DriverScoreboardConfiguration } from '@/domain/entities/DriverScoreboard'

export class UpdateDriverScoreRequest implements UpdateDriverScoreRequestInterface {
  public payload?: DriverScoreboardConfiguration[]

  constructor(payload: DriverScoreboardConfiguration[]) {
    this.payload = payload
  }

  public toPayload(): string {
    const payload = this.payload?.map((data) => ({
      driver_score_config_id: data.id,
      label: data.label,
      score_type: data.scoreType,
      score_value: data.scoreValue,
      poin: data.point,
      action: data.action,
      created_at: data.createdAt,
      start_date: data.startDate,
      end_date: data.endDate
    }))
    return JSON.stringify(payload)
  }
}
