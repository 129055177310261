import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  DRIVER_PAGINATION,
  EventBus,
  EventBusConstants,
  Utils,
} from '@/app/infrastructures/misc'
import { DriverScoreboardPresenter } from '@/app/ui/presenters/DriverScoreboardPresenter'
import {
  Driver,
  Drivers,
  DriverScoreboardConfiguration,
  DriverScoreboardConfigurations,
  DriverScoreReport,
  DriverScoreReportCalculate,
} from '@/domain/entities/DriverScoreboard'
import { UpdateDriverScoreRequest } from '@/data/payload/api/DriverScoreboardRequests'
import { Pagination } from '@/domain/entities/Pagination'

export interface DriverScoreboardState {
  isLoading: boolean
  isLoadingExport: boolean
  configurationData: DriverScoreboardConfiguration[]
  driverData: Driver[]
  driverPaginationData: Pagination
  driverScoreReport: DriverScoreReport
  driverScoreReportCalculate: DriverScoreReportCalculate
}

@Module({ namespaced: true, store, name: 'driverScoreboard', dynamic: true })
class DriverScoreboardController extends VuexModule
  implements DriverScoreboardState {
  private presenter: DriverScoreboardPresenter = container.resolve(
    DriverScoreboardPresenter
  )
  public isLoading = false
  public isLoadingExport = false
  public configurationData: DriverScoreboardConfiguration[] = []
  public driverData: Driver[] = []
  public driverPaginationData = new Pagination(1, DRIVER_PAGINATION)
  public driverScoreReport: DriverScoreReport = {}
  public driverScoreReportCalculate: DriverScoreReportCalculate = {}
  public constant = {
    driverType: [
      {
        value: 'KVP',
        label: 'KVP',
      },
      {
        value: 'POS',
        label: 'POS',
      },
      {
        value: '',
        label: 'Semua',
      },
    ],
    sort: [
      {
        value: 'score-desc',
        label: 'Poin besar-kecil'
      },
      {
        value: 'score-asc',
        label: 'Poin kecil-besar'
      },
      {
        value: 'name-asc',
        label: 'A-Z'
      },
      {
        value: 'name-desc',
        label: 'Z-A'
      }
    ]
  }

  @Action({ rawError: true })
  public getAll() {
    this.setLoading(true)

    this.presenter
      .getAll()
      .then(res => {
        this.setLoading(false)
        this.setDriverScoreboardConfigData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Scoreboard Config Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateConfiguration(form: DriverScoreboardConfiguration[]) {
    this.setLoading(true)
    this.presenter
      .updateConfiguration(new UpdateDriverScoreRequest(form))
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_DRIVER_SCOREBOARD_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Driver Scoreboard Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDriverList(params: Record<string, string | number | undefined>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getDrivers(formattedParams)
      .then(res => {
        this.setDriverData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDriverScoreReport(params: {
    courierId: number
    dateFrom?: string
    dateTo?: string
  }) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify({
        dateFrom: params.dateFrom,
        dateTo: params.dateTo
      }),
      'snake_case'
    )

    this.presenter
      .getDriverScoreReport(params.courierId, formattedParams)
      .then(res => {
        this.setDriverScoreReport(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Score Report Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDriverScoreReportCalculate(params: {
    courierId: number
    dateFrom: string
    dateTo: string
  }) {
    this.setLoading(true)

    this.presenter
      .getDriverScoreReportCalculate(params.courierId, params.dateFrom, params.dateTo)
      .then(res => {
        this.setDriverScoreReportCalculate(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Score Report Calculate Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportDriverScoreReport(params: Record<string, string | number | undefined>) {
    this.setLoadingExport(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportDriverScoreReport(formattedParams)
      .then((url) => {
        window.location.href = url
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Driver Scoreboard Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExport(false)
      })
  }

  @Mutation
  private setDriverScoreboardConfigData(
    config: DriverScoreboardConfigurations
  ) {
    this.configurationData = config.data as DriverScoreboardConfiguration[]
  }

  @Mutation
  private setDriverData(drivers: Drivers) {
    this.driverPaginationData = drivers.pagination as Pagination
    this.driverData = drivers.data as Driver[]
  }

  @Mutation
  private setDriverScoreReport(driverScoreReport: DriverScoreReport) {
    this.driverScoreReport = driverScoreReport
  }

  @Mutation
  private setDriverScoreReportCalculate(driverScoreReportCalculate: DriverScoreReportCalculate) {
    this.driverScoreReportCalculate = driverScoreReportCalculate
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingExport(bool: boolean) {
    this.isLoadingExport = bool
  }
}

export default getModule(DriverScoreboardController)
