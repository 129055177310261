





















































































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/DriverScoreboardController'
import { DriverScoreReportCalculate } from '@/domain/entities/DriverScoreboard'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'

@Component({
  components: {
    LoadingOverlay,
  },
})
export default class DriverScoreDetailPage extends Vue {
  created(): void {
    this.fetchDriverScoreReport()
  }

  get driverScoreCalculate(): DriverScoreReportCalculate {
    return controller.driverScoreReportCalculate
  }

  get courierPhoneNumber(): string {
    return Utils.countryIndonesiaPhoneNumber(
      controller.driverScoreReportCalculate?.courierPhone || '',
      true
    )
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get scoreData(): { data: string; title: string }[] {
    return [
      {
        data: 'accept',
        title: 'Terima order',
      },
      {
        data: 'sla',
        title: 'Melakukan pickup',
      },
      {
        data: 'drop_off',
        title: 'Melakukan drop off',
      },
      {
        data: 'not_accept',
        title: 'Order tidak diterima',
      },
    ]
  }

  get totalPoint(): number {
    if (!controller.driverScoreReport.data) return 0

    const scoreData = controller.driverScoreReport.data

    return Object.keys(scoreData)
      .map(dataName => {
        return scoreData[dataName].reduce(
          (acc: number, curr) => acc + Number(curr.totalPoint),
          0
        )
      })
      .reduce((acc, curr) => acc + curr, 0)
  }

  private formatLabel(rules: string): string {
    // eslint-disable-next-line no-useless-escape
    const lessThanOrEqual = /^\<\=([0-9]+)$/
    if (lessThanOrEqual.test(rules)) {
      return rules.replace(
        lessThanOrEqual,
        (_, match) => `< ${match.padStart(2, '0')} Menit`
      )
    }

    // eslint-disable-next-line no-useless-escape
    const between = /^([0-9]+)\>x\<\=([0-9]+)$/
    if (between.test(rules)) {
      return rules.replace(
        between,
        (_, match1, match2) => `> ${match1.padStart(2, '0')} - ${match2} Menit`
      )
    }

    const label: Record<string, string> = {
      sameday: 'Sameday',
      non_sameday: 'Tidak Sameday',
      ignore: 'Diabaikan',
      pickup_cancel: 'Dibatalkan',
      pickup_reject: 'Ditolak',
    }

    if (label[rules]) return label[rules]

    return rules
  }

  get date(): { dateFrom: string; dateTo: string } {
    const date = this.$route.params.date.split('~')
    const dateFormat = 'ddd, DD MMM YYYY'

    return {
      dateFrom: dayjs(date[0])
        .locale(dayjsID)
        .format(dateFormat),
      dateTo: dayjs(date[1])
        .locale(dayjsID)
        .format(dateFormat),
    }
  }

  get totalDate(): number {
    if (!controller.driverScoreReportCalculate?.data?.accept[0]?.detail)
      return 0
    return controller.driverScoreReportCalculate?.data?.accept[0]?.detail.length
  }

  private dateFormat(date: string): string {
    return dayjs(date)
      .locale(dayjsID)
      .format('DD MMM')
  }

  get tableWidth(): string {
    if (this.totalDate < 4) return '100%'

    return this.totalDate * 260 + 'px'
  }

  private fetchDriverScoreReport() {
    let date = this.$route.params.date.split('~')
    let dateTo = dayjs(date[1])
    if (!dateTo.isSame(new Date(), 'day')) {
      dateTo = dateTo.endOf('day')
    } else {
      dateTo = dayjs().subtract(1, 'minutes')
    }

    controller.getDriverScoreReportCalculate({
      courierId: Number(this.$route.params.courierId),
      dateFrom: dayjs(date[0])
        .startOf('day')
        .toISOString(),
      dateTo: dateTo.toISOString(),
    })
  }

  get pointPerDay(): number[] {
    if (this.totalDate === 0) return []

    const data = controller.driverScoreReportCalculate?.data

    const countPerDay = (dataType: string, i: number) => {
      if (!data || !data[dataType]) return 0

      return data[dataType].reduce((acc: number, curr) => {
        const total = curr?.detail ? curr.detail[i]?.totalPoint : 0
        return acc + (total ?? 0)
      }, 0)
    }

    return [...Array(this.totalDate)].map((_, i) => {
      const accept = countPerDay('accept', i)

      const pickup = countPerDay('sla', i)

      const dropOff = countPerDay('drop_off', i)

      const notAccept = countPerDay('not_accept', i)

      return (
        Number(accept) + Number(pickup) + Number(dropOff) + Number(notAccept)
      )
    })
  }

  get totalData(): number {
    return this.pointPerDay.reduce((acc, curr) => acc + curr, 0)
  }
}
