



























































































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/DriverScoreboardController'
import Button from '@/app/ui/components/Button/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import { DriverScoreReport } from '@/domain/entities/DriverScoreboard'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import dayjs from 'dayjs'

interface Parameter {
  dateFrom?: string
  dateTo?: string
}

@Component({
  components: {
    Button,
    DateTimePicker,
    LoadingOverlay,
  },
})
export default class DriverScorePage extends Vue {
  parameters: Parameter = {}
  rangeDateValid = true

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        dateFrom:
          queries.dateFrom ||
          dayjs()
            .subtract(2, 'days')
            .format('YYYY-MM-DD'),
        dateTo:
          queries.dateTo ||
          dayjs()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
      }
    }

    this.fetchDriverScoreReport()
  }

  get driverScoreReport(): DriverScoreReport {
    return controller.driverScoreReport
  }

  get courierPhoneNumber(): string {
    return Utils.countryIndonesiaPhoneNumber(
      controller.driverScoreReport?.courierPhone || '',
      true
    )
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get scoreData(): { data: string; title: string }[] {
    return [
      {
        data: 'accept',
        title: 'Terima order',
      },
      {
        data: 'sla',
        title: 'Melakukan pickup',
      },
      {
        data: 'drop_off',
        title: 'Melakukan drop off',
      },
      {
        data: 'not_accept',
        title: 'Order tidak diterima',
      },
    ]
  }

  private changeDate() {
    const dateFrom = dayjs(this.parameters.dateFrom).startOf('day')
    const dateTo = dayjs(this.parameters.dateTo).endOf('day')
    let isValidRange = true

    if (dateTo.diff(dateFrom, 'days') > 30) {
      Vue.notify({
        title: 'Error',
        text: 'Max range date is 30 days',
        type: 'error',
        duration: 5000,
      })

      isValidRange = false
    }

    this.rangeDateValid = isValidRange
  }

  get totalPoint(): number {
    if (!controller.driverScoreReport.data) return 0

    const scoreData = controller.driverScoreReport.data

    return Object.keys(scoreData)
      .map(dataName => {
        return scoreData[dataName].reduce(
          (acc: number, curr) => acc + Number(curr.totalPoint),
          0
        )
      })
      .reduce((acc, curr) => acc + curr, 0)
  }

  private formatLabel(rules: string): string {
    // eslint-disable-next-line no-useless-escape
    const lessThanOrEqual = /^\<\=([0-9]+)$/
    if (lessThanOrEqual.test(rules)) {
      return rules.replace(
        lessThanOrEqual,
        (_, match) => `< ${match.padStart(2, '0')} Menit`
      )
    }

    // eslint-disable-next-line no-useless-escape
    const between = /^([0-9]+)\>x\<\=([0-9]+)$/
    if (between.test(rules)) {
      return rules.replace(
        between,
        (_, match1, match2) => `> ${match1.padStart(2, '0')} - ${match2} Menit`
      )
    }

    const label: Record<string, string> = {
      sameday: 'Sameday',
      non_sameday: 'Tidak Sameday',
      ignore: 'Diabaikan',
      pickup_cancel: 'Dibatalkan',
      pickup_reject: 'Ditolak',
      pickup_accept: 'Pickup Diterima',
    }

    if (label[rules]) return label[rules]

    return rules
  }

  private updateRangeDate() {
    this.$router.replace({
      query: {
        ...this.parameters,
      },
    })

    this.fetchDriverScoreReport()
  }

  private fetchDriverScoreReport() {
    let dateTo = dayjs(this.parameters.dateTo)
    if (!dateTo.isSame(new Date(), 'day')) {
      dateTo = dateTo.endOf('day')
    } else {
      dateTo = dayjs().subtract(1, 'minutes')
    }

    controller.getDriverScoreReport({
      courierId: Number(this.$route.params.courierId),
      dateFrom: dayjs(this.parameters.dateFrom)
        .startOf('day')
        .toISOString(),
      dateTo: dateTo.toISOString(),
    })
  }
}
